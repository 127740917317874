import * as React from "react";
import { memo } from "react";
import FrameComponent from "./frame-component";
import "./contact-modal-thank-you.css";

const ContactModalThankYou = memo(({ onClose }) => {
  return (
    <div className="contact-modal-thank-you">
      <FrameComponent onClose={onClose} />
      <img
        className="icon1"
        loading="eager"
        alt=""
        src="/icon.svg"
        onClick={onClose}
      />
    </div>
  );
});

export default ContactModalThankYou;
