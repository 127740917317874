import * as React from "react";
import { memo } from "react";
import "./frame-component.css";

const FrameComponent = memo(({ onClose }) => {
  return (
    <section className="contact-modal-thank-you-inner">
      <div className="formlabel-parent">
        <div className="formlabel2">
          <b className="label7">Thanks for reaching out!</b>
        </div>
        <div className="formlabel3">
          <div className="label8">
            We’ll get back to you as soon as possible.
          </div>
        </div>
        <button className="button32">
          <div className="base16">
            <img className="masked-icon32" alt="" src="/masked-icon.svg" />
            <div className="button33" onClick={onClose}>
              Return
            </div>
            <img
              className="masked-icon33"
              alt=""
              src="/masked-icon3.svg"
              onClick={onClose}
            />
          </div>
        </button>
      </div>
    </section>
  );
});

export default FrameComponent;
