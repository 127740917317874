import * as React from "react";
import { memo, useMemo } from "react";
import "./transblue.css";

const Transblue = memo(
  ({
    unsplashC5DLhUkEWfM,
    nationalMultiSiteFacility,
    transblue,
    efficientlyCoordinatingNa,
    iconFrames,
    propMinHeight,
    propHeight,
    href,
  }) => {
    const frameDivStyle = useMemo(() => {
      return {
        minHeight: propMinHeight,
      };
    }, [propMinHeight]);

    const iconFramesStyle = useMemo(() => {
      return {
        height: propHeight,
      };
    }, [propHeight]);

    return (
      <div className="transblue">
        <img
          className="unsplashc5dlhukewfm-icon"
          loading="eager"
          alt=""
          src={unsplashC5DLhUkEWfM}
        />
        <div className="frame-parent">
          <div className="frame-group">
            <div className="national-multi-site-facility-parent">
              <div className="national-multi-site-facility">
                {nationalMultiSiteFacility}
              </div>
              <div className="transblue1">{transblue}</div>
            </div>
            <div className="efficiently-coordinating-natio">
              {efficientlyCoordinatingNa}
            </div>
          </div>
          <div
            className="button-parent"
            style={frameDivStyle}
            onClick={() => window.open(href)}
          >
            <button className="button14">
              <div className="base7">
                <img className="masked-icon14" alt="" src="/masked-icon6.svg" />
                <div className="button15">Go there now</div>
                <img
                  className="masked-icon15"
                  alt=""
                  src="/masked-icon-1.svg"
                />
              </div>
            </button>
            <img
              className="icon-frames"
              alt=""
              src={iconFrames}
              style={iconFramesStyle}
            />
          </div>
        </div>
      </div>
    );
  }
);

export default Transblue;
