import * as React from "react";
import { memo } from "react";
import * as emailjs from "emailjs-com";
import "./contact-modal.css";

const errorStyle = {
  color: "red",
  marginBottom: "10px",
  marginTop: "5px",
  fontFamily: "Inter",
  fontSize: "14px",
};

const ContactModal = memo(({ onClose, openThankYouModal }) => {
  const [formValues, setFormValues] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });
  const [errors, setErrors] = React.useState({});

  const validate = () => {
    let errors = {};
    let isValid = true;

    if (formValues.firstName === "") {
      errors.firstName = true;
      isValid = false;
    }
    if (formValues.lastName === "") {
      errors.lastName = true;
      isValid = false;
    }
    if (formValues.email === "") {
      errors.email = true;
      isValid = false;
    }
    if (formValues.phone === "") {
      errors.phone = true;
      isValid = false;
    }
    if (formValues.message === "") {
      errors.message = true;
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleChange = (e) => {
    setFormValues((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
    setErrors({
      ...errors,
      [e.target.id]: false,
    });
  };

  const submit = (e) => {
    e.preventDefault();
    console.log(formValues);

    let isValid = validate();

    if (isValid) {
      try {
        // Send formValues to your backend
        emailjs
          .send(
            "service_gekurtf",
            "template_rghzo4q",
            formValues,
            "user_iLZ3jXyTzXi5zQFlgf5DG"
          )
          .then((res) => {
            console.log(res);
            onClose();
            openThankYouModal();
          });
      } catch (err) {
        alert(err.message);
      }
    }
  };

  return (
    <>
      <div className="contact-modal">
        <section className="contact-frame">
          <header className="form-label-frame">
            <div className="formlabel">
              <h1 className="label">Let's get in touch!</h1>
            </div>
            <div className="formlabel1">
              <h2 className="label1">How can we get ahold of each other?</h2>
            </div>
          </header>
          <form className="inner-frame-parent" onSubmit={submit}>
            <div className="inner-frame">
              <div className="textfield">
                <div className="input">
                  <input
                    className="content"
                    placeholder="Enter your first name."
                    type="text"
                    id="firstName"
                    onChange={handleChange}
                    value={formValues.firstName}
                  />
                </div>
                <div className="label-container">
                  <div className="label2">First Name</div>
                </div>
                <div className="formhelpertext">
                  <div className="helper-text">Helper text</div>
                </div>

                {errors.firstName && (
                  <div style={errorStyle}>First name is required</div>
                )}
              </div>
              <div className="textfield1">
                <div className="input1">
                  <input
                    className="content1"
                    placeholder="Enter your last name."
                    type="text"
                    id="lastName"
                    onChange={handleChange}
                    value={formValues.lastName}
                  />
                </div>
                <div className="label-container1">
                  <div className="label3">Last Name</div>
                </div>
                <div className="formhelpertext1">
                  <div className="helper-text1">Helper text</div>
                </div>
                {errors.lastName && (
                  <div style={errorStyle}>Last name is required</div>
                )}
              </div>
              <div className="textfield2">
                <div className="input2">
                  <input
                    className="content2"
                    placeholder="Enter your email address."
                    type="text"
                    id="email"
                    onChange={handleChange}
                    value={formValues.email}
                  />
                </div>
                <div className="label-container2">
                  <div className="label4">Email Address</div>
                </div>
                <div className="formhelpertext2">
                  <div className="helper-text2">Helper text</div>
                </div>
                {errors.email && (
                  <div style={errorStyle}>Email is required</div>
                )}
              </div>
              <div className="textfield3">
                <div className="input3">
                  <input
                    className="content3"
                    placeholder="Enter your phone number."
                    type="text"
                    id="phone"
                    onChange={handleChange}
                    value={formValues.phone}
                  />
                </div>
                <div className="label-container3">
                  <div className="label5">Phone Number</div>
                </div>
                <div className="formhelpertext3">
                  <div className="helper-text3">Helper text</div>
                </div>
                {errors.phone && (
                  <div style={errorStyle}>Phone Number is required</div>
                )}
              </div>
              <div className="textfield4">
                <div className="input4">
                  <input
                    className="content4"
                    placeholder="Type your message here."
                    type="text"
                    id="message"
                    onChange={handleChange}
                    value={formValues.message}
                  />
                </div>
                <div className="label-container4">
                  <div className="label6">How can we help you?</div>
                </div>
                <div className="formhelpertext4">
                  <div className="helper-text4">Helper text</div>
                </div>
                {errors.message && (
                  <div style={errorStyle}>Message is required</div>
                )}
              </div>
            </div>
            <button className="button">
              <div className="base">
                <img className="masked-icon" alt="" src="/masked-icon.svg" />
                <div className="button1">Submit</div>
                <img className="masked-icon1" alt="" src="/masked-icon3.svg" />
              </div>
            </button>
          </form>
        </section>
        <img
          className="icon"
          loading="eager"
          alt=""
          src="/icon.svg"
          onClick={onClose}
        />
      </div>
    </>
  );
});

export default ContactModal;
